<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">
    <Snackbar ref="snackbar" />

    <v-dialog v-model="dialog.filter" persistent width="650">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #f05326"
        >
          <span class="white--text"> Filter </span>
          <v-spacer></v-spacer>
          <v-icon
            @click="dialog.filter = false"
            color="white"
            :disabled="process.run">
            mdi-close-circle
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-text-field
            outlined
            dense
            flat
            v-model="formFilter.license"
            label="Please Input License Code"
            color="#F05326"
          >
          </v-text-field>
          <v-autocomplete
            :search-input.sync="searchM"
            outlined
            dense
            flat
            v-model="formFilter.member_id"
            :items="member.list"
            label="Pelase Select Member"
            item-value="id"
            item-text="fullname"
            color="#F05326"
          >
          </v-autocomplete>
          <v-select
            outlined
            dense
            flat
            v-model="formFilter.is_complete"
            :items="[
              { text: 'Unfinished Class', value: 0 },
              { text: 'Class is Finished', value: 1 },
            ]"
            label="Please Select Class Status"
            color="#F05326"
          >
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            :loading="process.run"
            class="text-capitalize"
            @click="closeFilter('close')"
          >
            Close
          </v-btn>
          <v-btn
            depressed
            class="text-capitalize white--text"
            color="red"
            :loading="process.run"
            @click="closeFilter('reset')"
          >
            Reset
          </v-btn>
          <v-btn
            depressed
            color="#EF6C00"
            class="white--text text-capitalize"
            :loading="process.run"
            @click="fetch()"
          >
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DETAIL -->
    <v-dialog v-model="dialog.detail" persistent width="750" scrollable>
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #f05326"
        >
          <span class="white--text"> Detail Member Redeem </span>
          <v-spacer></v-spacer>
          <v-icon @click="dialog.detail = false" color="white">
            mdi-close-circle
          </v-icon>
        </v-card-title>
        <v-card-text style="height: 500px">
          <v-subheader class="font-weight-bold text-h5">Personal Information</v-subheader>
          <div style="border-bottom: 1px solid #f05326" class="mx-3"></div>
          <v-simple-table class="px-3">
            <template>
              <tbody>
                <tr>
                  <th class="text-second pl-0" width="30%">Full Name</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{
                      detail_member.member ? detail_member.member.fullname : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Email</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{
                      detail_member.member ? detail_member.member.email : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Phone Number</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{
                      detail_member.member ? detail_member.member.phone : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Date of Birth</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{
                      detail_member.member
                        ? detail_member.member.date_of_birth
                        : "-" | date
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider class="mx-3"></v-divider>

          <v-subheader class="font-weight-bold text-h5 mt-3">
            Program Data
          </v-subheader>
          <div style="border-bottom: 1px solid #f05326" class="mx-3"></div>
          <v-simple-table class="px-3">
            <template>
              <tbody>
                <tr>
                  <th class="text-second pl-0" width="30%">Program Name</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{
                      detail_member.program ? detail_member.program.title : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">
                    Learning Target Title
                  </th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{
                      detail_member.program
                        ? detail_member.program.learning_targets.studies.title
                        : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">
                    Learning Target Title
                  </th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{
                      detail_member.program
                        ? detail_member.program.learning_targets.studies.title
                        : "-"
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider class="mx-3"></v-divider>

          <v-subheader class="font-weight-bold text-h5 mt-3">Competence</v-subheader>
          <div style="border-bottom: 1px solid #f05326" class="mx-3"></div>
          <v-simple-table class="px-3" v-if="detail_member.program">
            <template>
              <tbody>
                <tr
                  v-for="(item, index) in detail_member.program
                    ? detail_member.program.learning_targets.competence
                    : 0"
                  :key="index">
                  <th class="text-second pl-0" width="30%">{{ item }}</th>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider class="mx-3"></v-divider>

          <v-subheader class="font-weight-bold text-h5 mt-3">Data Score Test</v-subheader>
          <div style="border-bottom: 1px solid #f05326" class="mx-3"></div>
          <v-simple-table class="px-3" v-if="detail_member.results">
            <template>
              <tbody>
                <tr
                  v-for="(item, index) in detail_member.results
                    ? detail_member.results.scores.test
                    : 0"
                  :key="index"
                >
                  <th class="text-second pl-0" width="30%">
                    {{ item.type.replace("_", " ").toUpperCase() }}
                  </th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">{{ item.score }} poin</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider class="mx-3"></v-divider>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG CHANGE MEMBERS -->
    <v-dialog v-model="dialog.change_member" persistent width="550">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #f05326"
        >
          <span class="white--text"> Change Member Redeem </span>
          <v-spacer></v-spacer>
          <v-icon
            @click="
              dialog.change_member = false;
              form.member_id = '';
            "
            color="white">
            mdi-close-circle
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-autocomplete
              :search-input.sync="searchMember"
              outlined
              dense
              flat
              v-model="form.member_id"
              :items="itemsMember"
              label="Please Select Member"
              :item-value="'id'"
              :filter="filterObject"
              color="#F05326"
            >
              <template #selection="{ item }"
                >{{ item.fullname }} - {{ item.phone }}</template
              >
              <template #item="{ item }">
                {{ item.fullname }} - {{ item.phone }}
              </template>
            </v-autocomplete>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#F05326"
            class="white--text text-capitalize"
            depressed
            @click="changeMemberRedeem"
          >
            Save Change
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="mt-5">
      <div class="title black--text mb-2">List Member Redeem</div>
      <v-row justify="space-between" class="mt-2">
        <v-col cols="4">
          <div>
            <v-text-field
              solo
              filled
              dense
              flat
              :hide-details="true"
              placeholder="Search Member Redeem . . ."
              autocomplete="off"
              v-model="searching"
              :readonly="process.run"
              clearable
              color="#F05326"
              @click:clear="searching = ''"
              prepend-inner-icon="mdi-magnify"
            >
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <div class="d-flex float-right">
            <v-btn
              depressed
              :loading="process.run"
              @click="filter"
              elevation="0"
              color="#F5F5F5"
              class="black--text text-capitalize ml-5"
            >
              Filter by
              <v-icon right>mdi-filter-variant</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div class="mt-3 mb-8">
        <v-skeleton-loader
          class="ma-auto"
          :loading="$store.state.process.run"
          :tile="false"
          transition="scale-transition"
          type="table-tbody"
        >
          <v-data-table
            class="table-list"
            hide-default-footer
            fixed-header
            :loading="$store.state.process.run || process.run"
            :headers="headers"
            :items="items.list"
            :items-per-page.sync="items.limit"
            :page.sync="items.page"
            :server-items-length="items.total"
            item-key="id"
            :footer-props="{
              itemsPerPageText: 'Data per halaman',
              'items-per-page-options': [5, 10, 20, 50, 100, 200, 350],
            }"
          >
            <template slot="footer" slot-scope="{ props }">
              <Pagination
                @reload="fetch(page)"
                @changeLimit="
                  limit = $event;
                  fetch();
                "
                @changePage="
                  page = $event;
                  fetch($event);
                "
                :model="props"
              />
            </template>

            <template slot="item" slot-scope="props">
              <tr class="mx-3 px-3 box-shadow">
                <td class="text-start white">
                  {{ props.item.license }}
                </td>
                <td class="text-start white">
                  {{ props.item.member.fullname }}
                </td>
                <td class="text-start white">
                  {{ props.item.member.email }}
                </td>
                <td class="text-center white">
                  {{ props.item.member.phone }}
                </td>
                <td class="text-center white">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        @click="change_redeem(props.item.id)"
                      >
                        <v-icon>mdi-cog-refresh-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Change Member Redeem</span>
                  </v-tooltip>
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        @click="change_redeem(props.item)">
                        <v-icon>mdi-eye-arrow-right-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Lihat Member Redeem</span>
                  </v-tooltip> -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="warning"
                        v-bind="attrs"
                        v-on="on"
                        @click="details(props.item)"
                      >
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Detail Member</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, put, destroy } from "@/service/Axios";
import GridTable from "@/components/GridTable";
import Pagination from "@/components/Pagination.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Snackbar from "@/components/Snackbar.vue";
export default {
  components: { GridTable, Pagination, Breadcrumbs, Snackbar },
  data() {
    return {
      searchMember: "",
      searchM: "",

      title: "List Users",
      limit: 10,
      page: 1,
      selected: {
        pagination: 0,
      },
      headers: [
        { text: "Lisensi", align: "start", value: "license", sortable: false },
        {
          text: "User Full Name",
          align: "start",
          value: "member.fullname",
          sortable: false,
        },
        {
          text: "Email",
          align: "start",
          value: "member.email",
          sortable: false,
        },
        {
          text: "Phone Number",
          align: "center",
          value: "member.phone",
          sortable: false,
        },
        { text: "Actions", align: "center", value: "action", sortable: false },
      ],
      items: {},
      member: {},
      detail_member: {},
      member_redeem: {},
      searching: "",
      process: {
        run: false,
      },
      formFilter: {
        member_id: "",
        license: "",
        is_complete: "",
        program_id: "",
      },
      dialog: {
        filter: false,
        detail: false,
        change_member: false,
      },
      member: {},
      itemsMember: {},
      form: {
        id: "",
        member_id: "",
      },
    };
  },
  watch: {
    "selected.pagination": function (val) {
      this.fetch(val + 1);
    },
    searching: _.debounce(function (newVal) {
      this.searching = newVal;
      this.fetch();
    }, 500),
    searchM() {
      this.memberList()
    },
    searchMember() {
      this.memberList()
    }
  },
  created() {},
  mounted() {
    this.memberList();
    this.fetch();
  },
  methods: {
    filterObject(item, queryText, itemText) {
      return (
        item.fullname
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.phone.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    changePage(event) {
      if (event === undefined) {
        this.selected.pagination = 0;
      }
    },
    filter() {
      this.dialog.filter = true;
      // this.$refs.snackbar.open("#4CAF50", `Mohon Maaf Fitur Belum Tersedia`);
    },
    closeFilter(state) {
      this.dialog.filter = false;
      if (state != "close") {
        this.formFilter = {
          member_id: "",
          license: "",
          is_complete: "",
          program_id: "",
        };
        this.fetch();
      }
    },
    async fetch(paging) {
      let _self = this;
      _self.process.run = true;

      await get(`users/v1/superuser/member_redeem/list`, {
        params: {
          limit: this.limit,
          page: paging,
          sort: "created_at",
          dir: "asc",
          member_id: this.formFilter.member_id,
          license: this.searching || this.formFilter.license,
          is_complete: this.formFilter.is_complete,
        },
      }).then((response) => {
        let res = response.data;
        this.items = res.data;
        this.process.run = false;
        this.$store.state.process.run = false;
        this.totalData = res.data.total;
        this.closeFilter("close");
      });
    },
    details(item) {
      this.detail_member = item;
      this.dialog.detail = true;
    },
    async memberList() {
      const search = this.dialog.change_member
        ? this.searchMember
        : this.searchM;
      await get(`users/v1/superuser/member`, {
        params: {
          limit: 20,
          sort: "created_at",
          dir: "asc",
          q: search,
        },
      }).then((response) => {
        let res = response.data;
        this.member = res.data;
        this.itemsMember = res.data.list;
        this.totalData = res.data.total_data;
      });
    },
    change_redeem(id) {
      this.dialog.change_member = true;
      this.form.id = id;
    },
    changeMemberRedeem() {
      let _self = this;
      _self.process.run = true;
      put(`users/v1/superuser/member_redeem/change_member`, {
        data: {
          member_redeem_id: this.form.id,
          member_id: this.form.member_id,
        },
      }).then((response) => {
        let res = response.data;
        if (res.status) {
          this.$refs.snackbar.open("#4CAF50", `Berhasil Merubah Member Redeem`);
          this.dialog.change_member = false;
          this.form = {
            member_id: "",
          };
          this.fetch();
        } else {
          this.$refs.snackbar.open("#D32F2F", `Gagal Merubah Member Redeem`);
        }
        this.process.run = false;
      });
    },
  },
};
</script>

<style>
</style>